<template>
  <div>
    <div class="footer-bar-wrap">
      <div class="ad">智能客服系统助力企业解决售后服务，提高服务质量，节约运营成本</div>
      <div class="footer-bar">
        <div class="container group-list">
          <div class="group">
            <div class="title">关于我们</div>
            <div>
              <router-link to="/helpCenter">帮助中心</router-link>
            </div>
            <div>软件项目管理</div>
            <div>
              <a href="https://www.mlpmis.cn">默蓝项目管理信息系统</a>
            </div>
          </div>
          <div class="split-line"></div>
          <div class="group">
            <div class="title">商务经理</div>
            <div>商务联系：{{ $store.state.setting.contactUsername }}</div>
            <div style="display: none">联系电话：{{ $store.state.setting.contactUsTel }}</div>
            <div>邮箱地址：{{ $store.state.setting.email }}</div>
            <!--
            <div>商务合作：{{ $store.state.setting.mediaCooperation }}</div>
            -->
          </div>
          <div class="split-line"></div>
          <div class="group">
            <div class="title">联系我们</div>

            <!--
            <div>公司地址：{{ $store.state.setting.companyAddress }}</div>
            <div>技术咨询: {{ $store.state.setting.technologyConsultingPhone }}</div>
            -->
            <div>QQ号码：{{ $store.state.setting.contactUsQQ }}</div>

            <div>电子邮箱：{{ $store.state.setting.email }}</div>
            <div>邮政编码：{{ $store.state.setting.postcode }}</div>
          </div>
          <div class="group" style="float: right;line-height: 20px;text-align: center;">
            <div class="title">{{ $store.state.setting.contactUsTime }}</div>
            <template v-if="$store.state.setting.businessQrCode">
              <el-image style="height: 100px" :src="$store.state.setting.businessQrCode"></el-image>
            </template>
            <div>微信扫一扫</div>
          </div>
        </div>
      </div>
      <div class="pad-tb15 bottom-line">
        <div class="container"
             style="text-align: center;display: flex; justify-content: space-between;">
          <div style="display: inline-block">
            <span>
              <a href="http://www.ihzml.cn/"
                 target="_blank">本站由{{ $store.state.setting.technicalSupport }}提供技术支持
              </a> &nbsp;
              版权所有&nbsp;@2021&nbsp;<a href="http://beian.miit.gov.cn/"
                                                 target="_blank">
              浙ICP备17004325号-4
            </a>
            </span>
            <span>默蓝客服系统 AI Online Customer Service</span>
            <span style="margin-left: 20px;">官方网址：
              <a :href="$store.state.setting.officialSite"
                 target="_blank">{{ $store.state.setting.officialSite }}
              </a>
            </span>
          </div>
          <div style="display: inline-block">
            <span class="mar-r15">
              <router-link to="/protocol/1">法律声明</router-link>
            </span>
            <span style="margin-left: 20px;">
              <router-link to="/protocol/2">隐私协议</router-link>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'GlobalFooter',
    props: [],
    data() {
      return {
        links: null
      }
    },
    methods: {},
    beforeMount() {
    }
  }
</script>

<style scoped lang="scss">
  .footer-bar-wrap {
    color: #929292;
    font-size: 12px;
    width: 100%;
    background-color: #303643;
    position: relative;
  }

  .footer-bar {
    padding: 10px 0 30px 0;
  }

  .group-list {
  }

  a, a:link, a:visited {
    color: #929292 !important;
  }

  .group {
    display: inline-block;
    vertical-align: top;
    line-height: 26px;
    text-align: left;
  }

  .group .title {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .group ::v-deep img {
    height: 90px;
    width: 95px;
    object-fit: cover;
  }

  .split-line {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0 50px;
  }

  .split-line:before {
    content: '';
    display: block;
    height: 80px;
    border-right: 1px solid white;
  }

  .bottom-line span + span:not(:last-child) {
    margin-left: 70px;
  }

  .bottom-line {
    border-top: 1px solid #5b616f;
  }

  .ad {
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100% 100%;
    background-image: url(/images/footer/bg-ad.jpg);
    padding: 46px 0;
    font-size: 20px;
    text-align: center;
    color: #fff;
  }
</style>
